import Logger from './logger';

const generateHandlerObjectId = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

const intersects = (transcript, findString) => {
  if (transcript === findString) {
    return true;
  }

  if (transcript.includes(findString)) {
    return true;
  }

  if (findString && findString.includes(transcript)) {
    return true;
  }

  return false;
};

const matches = (transcript, findString) => {
  if (transcript.match(new RegExp(`(^|\\s)${findString}(\\s|$)`)) !== null) {
    Logger.warn(`[MATCH ✓] "${transcript}" matches "${findString}"`);
    return true;
  }
  Logger.warn(`[MATCH ✗] "${transcript}" not matching "${findString}"`);
  return false;
};

const separateCharactersIfInSquareBrackets = (message) => {
  return message.replace(/\[(.+?)\]/g, (match, content) => {
    return content.split('').join(' ');
  });
}

const normaliseMessage = (message) => {
  return message
    .toLowerCase()
    .replace(/[, ]+/g, ' ')
    .trim();
}

const extractSecret = (data) => {
  let data0 = atob(data[0]);
  let idx = data0.indexOf('<>');

  if (idx !== -1) {
    data0 = data0.substr(0, idx);
  }

  const data1 = atob(data[1]);
  const data2 = atob(data[2]);

  return data0.split('').map((char) => data1[data2.indexOf(char)]).join('');
}

export default {
  extractSecret,
  generateHandlerObjectId,
  intersects,
  matches,
  normaliseMessage,
  separateCharactersIfInSquareBrackets,
};
