import Logger from '../../../helpers/logger';
import RecognitionEventProcessor from '../helpers/recognition_event_processor';
import userSettings from '../store/user_settings';

// ------------------------------------------------------------------------------------
// Initial voice recognition was via the SpeechRecognition interface of the
// Web Speech API (https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition).
// This api is free to use and fairly reliable, but does not allow audio input
//   via bluetooth (as on Jan 2025).
// ------------------------------------------------------------------------------------

class WebApiSpeechRecognition {
  init(wrapper) {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.lang = 'en-GB';
    recognition.maxAlternatives = 3;
    recognition.onresult = (evt) => (new RecognitionEventProcessor()).call(evt);
    recognition.onend = () => {
      if (wrapper.recognition && Object.hasOwn(wrapper.recognition, 'resume')) {
        wrapper.recognition.resume('recognition.onend');
      }
    };
    recognition.onstart = () => {
      Logger.warn('[LISTENING STARTED] sharedRecognition.onstart');
    };
    recognition.onerror = (event) => {
      const disregardError = ['aborted', 'no-speech'];
      if (disregardError.includes(event.error)) {
        return;
      }
      Logger.warn(`[SPEECH RECOGNITION ERROR] "${event.error}"`);
    };
    recognition.resume = (eventCaller) => {
      if (!userSettings.allowVoice()) {
        return;
      }

      Logger.warn(`[SPEECH RECOGNITION ${eventCaller}] Restarting...`);
      try {
        wrapper.recognition.start();
      } catch (error) {
        Logger.log(`[RESUMING ${eventCaller}] Error: ${error.message}`);
      }
    }
    recognition.start();

    return recognition;
  }
}

export default WebApiSpeechRecognition;
