class Store {
  constructor() {
    if (this.constructor.instance) {
      return this.constructor.instance;
    }

    this.hash = {};
    this.constructor.instance = this;
  }

  initOnceQueue(key) {
    if (this[key]) {
      return;
    }

    this.initQueue(key);
  }

  push(key, item) {
    this.#initHashSpaceOnce(key);

    this.hash[key].push(item);
    return this;
  }

  set(key, value) {
    this.accessor(key, value);
    return this;
  }

  get(key) {
    return this.hash[key];
  }

  increment(key) {
    this.#initHashSpaceOnce(key);

    if (!this.hash[key]) {
      this.hash[key] = 0;
    }
    this.hash[key] += 1;

    return this.hash[key];
  }

  recordStartTime() {
    this.set('activity_start_time', new Date());
  }

  timeElapsed() {
    const activityStartTime = this.get('activity_start_time');

    if (!activityStartTime) {
      return 0.0;
    }

    const activityEndTime = new Date();

    return (activityEndTime - activityStartTime) / 1000;
  }

  // private

  accessor(key, value = null) {
    this.#initHashSpaceOnce(key);

    if (value !== null) {
      this.hash[key] = value;
      return this;
    }

    return this.hash[key];
  }

  initQueue(key) {
    this[key] = [];
  }

  #initHashSpaceOnce(key) {
    if (this.hash[key]) {
      return;
    }

    this.#initHashSpace(key);
  }

  #initHashSpace(key) {
    this.hash[key] = [];
  }
}

export default Store;
