import DOM from '../helpers/dom_helper';
import SpeechController from './speech_controller';
import VoiceInputHandler from '../handlers/voice_input_handler';
import browserSupport from '../helpers/speech_synthesis';
import userSettings from '../store/user_settings';

class VoiceInputController extends SpeechController {
  static values = {
    autosubmit: Boolean,
    name: String,
  };

  connect() {
    if (!browserSupport()) {
      return;
    }

    userSettings.allowVoice(document.querySelector('head').dataset.allowVoice === 'true');
    userSettings.voiceSpeed(parseInt(document.querySelector('head').dataset.voiceSpeed));
    super.connect('input');
    DOM.focusFirstInput(this.element);
  }

  speechHandler(transcript) {
    const params = {
      activeElement: document.activeElement,
      inputHash: DOM.buildInputHash(this.nameValue),
      transcript,
    };

    return (new VoiceInputHandler()).call(params, this.autosubmitValue);
  }
}

export default VoiceInputController;
