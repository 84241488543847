import BaseMapController from './base_map_controller';
import whenAvailable from '../helpers/utility';
import { drawMarker } from './helpers/od_maps_helper';

export default class extends BaseMapController {
  async connect() {
    await super.connect();
    const { markers } = this.element.dataset;
    this.markersData = JSON.parse(markers);
    this.drawMarkers();
  }

  drawMarkers() {
    this.markersData.forEach((markerData) => {
      whenAvailable("google.maps.LatLng", function() {
        drawMarker(markerData, this.map, 'od-marker', this.markers);
      }.bind(this));
    });
  }
}
