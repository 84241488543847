import Logger from '../../../helpers/logger';
import RecognitionEventProcessor from '../helpers/recognition_event_processor';
import getFetch from '../../../helpers/fetch_helper';
import string from '../../../helpers/string_helper';

class DeepgramSocketSpeechRecognition {
  async init(wrapper) {
    await this.#initSocket(wrapper);

    const recognition = {
      stop: () => {
        Logger.warn('[SOCKET PAUSED]');
        wrapper.socket_status = 'PAUSED';
      },
      start: () => {
        Logger.warn('[SOCKET RESUMED]');
        wrapper.socket_status = 'OK';
      },
    }

    return recognition;
  }

  // private

  async #initSocket(wrapper) {
    try {
      const apiKeyResponse = await getFetch('/admin/javascript_secrets?key=DEEPGRAM_API_KEY');

      if (!apiKeyResponse.ok) {
        Logger.warn(`[FAILED FETCH] deepgram_api_key`);
        return;
      }

      const apiKeyData = await apiKeyResponse.json();
      const apiKey = string.extractSecret(apiKeyData.data);

      wrapper.socket = new WebSocket('wss://api.deepgram.com/v1/listen?model=nova-2', [
        'token',
        apiKey
      ]);

      const mediaRecorder = new MediaRecorder(wrapper.stream, { mimeType: 'audio/webm' })

      wrapper.socket.onopen = () => {
        Logger.warn('[SOCKET OPEN] Listening...');

        mediaRecorder.addEventListener('dataavailable', async (event) => {
          if (wrapper.socket_status === 'PAUSED') {
            return;
          }
          if (event.data.size > 0 && wrapper.socket.readyState == 1) {
            wrapper.recordStartTime();
            wrapper.socket.send(event.data)
          }
        })
        mediaRecorder.start(1000)
      }

      wrapper.socket.onmessage = (message) => {
        const received = JSON.parse(message.data);

        if (received.channel === undefined) {
          return;
        }

        const transcript = received.channel.alternatives[0].transcript;

        if (transcript && received.is_final) {
          Logger.warn({ model: 'deepgram', text: transcript, seconds: wrapper.timeElapsed() });

          const evt = {
            resultIndex: 0,
            results: [
              [{ transcript }],
            ],
          };
          (new RecognitionEventProcessor()).call(evt);
        }
      }

      wrapper.socket.onclose = () => {
        Logger.warn('[SOCKET CLOSED] !!!');
      }

      wrapper.socket.onerror = (error) => {
        Logger.warn('[SOCKET ERROR]');
        Logger.warn({ error });
      }

      wrapper.socket_status = 'OK';
      Logger.warn('[LISTENING STARTED: socket]');
    } catch (failure) {
      Logger.warn("[SOCKET INITIALIZATION FAILED]");
      Logger.warn({ failure });
    }
  }
}

export default DeepgramSocketSpeechRecognition;
