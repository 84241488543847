const whenAvailable = (name, callback) => {
  var interval = 10; // ms

  const isLoaded = (name) => {
    let result = window;
    for (const item of name.split(".")) {
      try {
        result = result[item];

        if (!result) {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
    return true;
  }

  window.setTimeout(function() {
    if (isLoaded(name)) {
      callback();
    } else {
      whenAvailable(name, callback);
    }
  }, interval);
}

export default whenAvailable;
