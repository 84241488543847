import Logger from '../../../helpers/logger';
import speechProcessor from '../store/speech_processor';
import userSettings from '../store/user_settings';

const calculatedTranscript = (evt) => {
  const preProcess = (transcript) => {
    // Ignore Known Instruction: "Pick 15, 1 foot"
    const knownPickQtyInstructionRegex = new RegExp(/^pick \d+ \d+ \w+/);
    if (transcript.match(knownPickQtyInstructionRegex)) {
      return 'known_instruction';
    }

    return transcript;
  }

  const processAlternative = (alternative, idx) => {
    const transcript = alternative.transcript.trim();

    if (!transcript) {
      return null;
    }

    userSettings.setLatestTranscriptionTimestamp();

    const preProcessedTranscript = preProcess(transcript);

    if (preProcessedTranscript === 'known_instruction') {
      return preProcessedTranscript;
    }

    if (idx === 0) {
      Logger.warn('\n--- CALCULATING TRANSCRIPTS ---');
    }

    Logger.warn(`[ALTERNATIVE ${idx}] "${preProcessedTranscript}"`);

    if (speechProcessor.evaluate(preProcessedTranscript)) {
      return preProcessedTranscript;
    }
  }

  const processResult = (result) => {
    const transcriptVariationsString = Object.values(result)
      .map((item) => `"${item.transcript.trim()}"`)
      .join(' OR ');
    Logger.logToScreen(`Heard: ${transcriptVariationsString}`);
    for (let j = 0; j < result.length; j += 1) {
      const matchedTranscript = processAlternative(result[j], j);

      if (matchedTranscript === 'known_instruction') {
        return '';
      }

      if (matchedTranscript) {
        return matchedTranscript;
      }
    }
  }

  for (let i = evt.resultIndex; i < evt.results.length; i += 1) {
    const matchedTranscript = processResult(evt.results[i]);
    if (matchedTranscript) {
      return matchedTranscript;
    }
  }

  return '';
};

export default calculatedTranscript;
