import SpeechController from './speech_controller';
import browserSupport from '../helpers/speech_synthesis';
import global from '../store/global';
import speechRecognition from '../store/speech_recognition';
import string from '../../../helpers/string_helper';
import userSettings from '../store/user_settings';

class VoiceClickController extends SpeechController {
  connect() {
    if (!browserSupport()) {
      return;
    }

    userSettings.allowVoice(document.querySelector('head').dataset.allowVoice === 'true');
    super.connect('command');
  }

  speechHandler(transcript) {
    const result = this.#evaluate(transcript);

    if (global.get('evaluate_only')) {
      return result;
    }

    if (result) {
      this.element.click();
    }

    return result;
  }

  toggle(evt) {
    if (speechRecognition.useDeepgram()) {
      // Needs to reload for fresh socket connection
      setTimeout(() => location.reload(), 100);
    } else {
      speechRecognition.toggleState();
    }
  }

  // private

  #evaluate(transcript) {
    const elementText = (
      this.element.innerText.trim() || this.element.value.trim()
    ).toLowerCase();

    return string.matches(transcript, elementText);
  }
}

export default VoiceClickController;
