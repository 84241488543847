const getFetch = (path) => {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  return fetch(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token,
    },
    credentials: 'same-origin',
  })
}

export default getFetch;
